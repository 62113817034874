<template>
  <div class="conversation-wrapper">
    <div v-if="loading" class="chat-loading-screen">
      <pulse-loader-v2 />
    </div>
    <div v-else-if="emptyConversation && installedApp" class="empty-chat-feedback">
      <img src="@/assets/feedbacks/lists/no-chat-messages.svg">
      <p class="t1 fc-blue-6">
        {{ $t('copy_2971') }}
      </p>
    </div>
    <div v-else-if="emptyConversation && !installedApp" class="empty-chat-feedback">
      <img src="@/assets/feedbacks/lists/no_guarda_app.svg">
      <p class="t1 fc-blue-6">
        {{ $t('copy_3010') }}
      </p>
    </div>
    <div v-else
         ref="messageList"
         data-testid="list_messages"
         class="conversation-area-container"
         @scroll.passive="handleScrollUp">
      <ul>
        <li v-for="(message, key) in messages"
            :key="message.messageId || message.reqId"
            class="channel-entry">
          <div v-if="messageTime(key).show">
            <p v-if="messageTime(key).header" class="t3 fc-blue-4">
              {{ messageTime(key).header }}
            </p>
            <p class="t3 fc-blue-4 center-text margin-y-1">
              {{ messageTime(key).text }}
            </p>
          </div>
          <message :message="message"
                   :member="getParsedSender(message.sender)"
                   :sms-scheduling-info="parseMessageSchedulingInfo(message, key)"
                   :show-name="members.length > 2"
                   :omit-avatar="discardAvatar(key)"
                   @send-via-sms="sendViaSms"
                   @cancel-schedule-sms="cancelScheduleSms" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Message from '@/components/chat/messages/Message.vue';
import sbUtils from '@/scripts/sendbird-integration/utils';
import PulseLoaderV2 from '@ui-kit/components/loaders/PulseLoaderV2.vue';

// Max time diff between messages. diffs >= 20 will produce time annotations and paint user avatar
const MAX_TIME_DIFF = 20;

export default {
  name: 'ConversationArea',
  components: {
    Message,
    PulseLoaderV2,
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
    members: {
      type: Array,
      required: true,
    },
    installedApp: {
      type: Boolean,
      default: false,
    },
    multiMemberChat: {
      type: Boolean,
      default: false,
    },
  },
  emits: [ 'send-message-via-sms', 'cancel-schedule-sms' ],
  data() {
    return {
      scrollingUp: false,
      indexOfLastMessageFromMember: null,
    };
  },
  async mounted() {
    this.scrollToEnd();
  },
  computed: {
    ...mapGetters({
      ready: 'status/ready',
      patientMember: 'chat/activeChannel/getMember',
    }),
    emptyConversation() {
      return this.messages && !this.messages.length;
    },
    loading() {
      return !this.ready('messages');
    },
  },
  watch: {
    messages() {
      this.getIndexOfLastMessageFromMember();
      if (this.scrollingUp) {
        this.scrollToStart();
      }
    },
  },
  updated() {
    // whenever data changes and the component re-renders, this is called.
    if (!this.scrollingUp) {
      this.$nextTick(() => this.scrollToEnd());
    }
    this.scrollingUp = false;
  },
  methods: {
    getIndexOfLastMessageFromMember() {
      for (let i = this.messages.length - 1; i >= 0; i -= 1) {
        if (this.messages[i].sender.userId === this.patientMember.userId) {
          this.indexOfLastMessageFromMember = i;
          return;
        }
      }
    },
    sendViaSms(message) {
      this.$emit('send-message-via-sms', message);
    },
    cancelScheduleSms(message, smsSchedulingNotificationType) {
      this.$emit('cancel-schedule-sms', message, smsSchedulingNotificationType);
    },
    parseMessageSchedulingInfo(message, index) {
      const history = (message.metaArrays || []).find(({ key }) => key === 'scheduling');
      // Ensure timestamps ordered
      const orderedActions = (history?.value || []).map(entry => JSON.parse(entry.replace(/'/g, '"')))
          .sort(({ ts: aTS }, { ts: bTS }) => bTS - aTS);
      return {
        smsBlocked: this.patientMember.preferences.smsBlocked,
        history: orderedActions,
        currentStatus: !!orderedActions.length && orderedActions[0].status,
        notificationType: !!orderedActions.length && orderedActions[0].notification_type,
        memberReplied: this.indexOfLastMessageFromMember && this.indexOfLastMessageFromMember > index,
      };
    },
    getParsedSender(sender = {}) {
      if (sbUtils.isPT(sender)) {
        const info = {
          ...sender,
          canReceiveSms: this.patientMember?.canReceiveSms,
        };

        return info;
      }
      const { patient } = this.patientMember || {};
      const senderInfo = {
        ...sender,
        picture: patient?.picture,
        gender: patient?.gender,
        memberPreferences: this.patientMember?.preferences || {},
      };

      return senderInfo;
    },
    handleScrollUp() {
      const listEl = this.$refs.messageList;
      if (listEl?.scrollTop === 0) {
        this.scrollingUp = true;
        this.$emit('loadMessageList');
      }
    },
    scrollToEnd() {
      const el = this.$refs.messageList;
      if (el) {
        el.scrollTop = el.scrollHeight;
      }
    },
    scrollToStart() {
      const listEl = this.$refs.messageList;
      if (listEl) {
        const topItemEl = listEl.lastElementChild.firstElementChild;
        listEl.scrollTop = topItemEl.offsetTop;
      }
    },
    getAdjacentMessagesByMessageKey(key) {
      const current = this.messages[key];
      const lastMessage = this.messages.length - 1 === key;
      const next = lastMessage ? {} : this.messages[key + 1];
      const previous = !key ? current : this.messages[key - 1];
      return {
        current,
        next,
        previous,
      };
    },
    discardAvatar(key) {
      const { current, next } = this.getAdjacentMessagesByMessageKey(key);
      const currentMD = current.createdAt;
      const nextMD = next.createdAt;
      const reachedMaxTimeDiff = this.$date(nextMD)
          .diffAtLeast(currentMD)(MAX_TIME_DIFF, 'minutes');
      const nextId = next.sender && next.sender.userId;
      const currentId = current.sender && current.sender.userId;
      return nextId === currentId && !reachedMaxTimeDiff;
    },
    messageTime(key) {
      const { current, previous } = this.getAdjacentMessagesByMessageKey(key);
      const currentMD = current.createdAt;
      const previousMD = previous.createdAt;

      const isToday = this.$date()
          .shToday(currentMD);
      const wasYesterday = this.$date()
          .shYesterday(previousMD);

      const reachedMaxTimeDiff = this.$date(currentMD)
          .diffAtLeast(previousMD)(MAX_TIME_DIFF, 'minutes');
      const firstMessageOfDay = !this.$date(currentMD)
          .isSameDay(previousMD) || !key;
      const showLabelWhen = reachedMaxTimeDiff || firstMessageOfDay;

      if (isToday) {
        const msgTime = this.$date(currentMD)
            .format('HH:mm');
        const text = firstMessageOfDay ? `Today, ${msgTime}` : msgTime;
        return {
          show: showLabelWhen,
          text,
        };
      }
      if (wasYesterday) {
        return {
          show: showLabelWhen,
          text: `Yesterday, ${this.$date(currentMD)
              .format('HH:mm')}`,
        };
      }
      const text = firstMessageOfDay ? this.$date(currentMD)
          .format('DD MMMM, HH:mm') : this.$date(currentMD)
                       .format('DD/MM, HH:mm');
      return {
        show: showLabelWhen,
        text,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.conversation-wrapper {
  height: 100%;
  position: relative;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.conversation-area-container {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  max-height: 100%;

  display: flex;
  flex-direction: column;

  // Hide scrollbar
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.channel-entry {
  margin: 0.5rem 0;
}

.chat-loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.empty-chat-feedback {
  position: relative;
  height: 100%;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    height: 8em;
    max-width: 30%;
  }

  p {
    color: getColor($greys, 5);
    margin-top: 20px;
  }
}
</style>

import Vue from 'vue';

import { URLS } from '@/configs/constants';
import store from '@/store';
import {
  DEFAULT_HEARTBEAT_INTERNAL,
  ONE_SEC_IN_MS,
} from '@/scripts/dpt-activity/constants';
import {
  createFSMService,
  createPageInactivityInterval,
  startActivityTrackingService,
} from '@/scripts/dpt-activity/utils';
import Timer from '@/scripts/dpt-activity/timer';
import { uuid4 } from '@/scripts/utils';

export default function (activityName) {
  return {
    data() {
      return {
        isEnabled: false,
        hasCreatedActivity: false,
        activityUuid: uuid4(),
        heartbeatInterval: undefined,
        inactivityInterval: undefined,
        startTime: new Date(),
        activityTimer: new Timer(),
        inactivityTimer: new Timer(),
      };
    },
    computed: {
      accountUuid() {
        let accountUuid;
        const memberUserId = store.getters['chat/activeChannel/getMember']?.userId;

        if (memberUserId) {
          const swordId = this.extractSendbirdID(memberUserId);
          const patients = store.getters['patient/getPatients'];
          if (swordId) {
            const patient = patients.find(p => (typeof swordId === 'number'
                ? p?.id === swordId
                : p?.member_account_id === swordId));
            accountUuid = patient?.member_account_id;
          }
        }
        return accountUuid;
      },
    },
    mounted() {
      this.isEnabled = true;

      // Continue tracking activity while we watch the Vuex store for changes to then update
      // this.accountUuid. Once we do have an accountUuid, we'll still have the total time
      // that the user was on the page of the patient.
      const service = createFSMService(this.activityTimer, this.inactivityTimer);

      startActivityTrackingService(service);

      this.inactivityInterval = createPageInactivityInterval(service, this.inactivityTimer);

      this.heartbeatInterval = setInterval(async () => {
        await this.sendDPTActivity();
      }, DEFAULT_HEARTBEAT_INTERNAL * ONE_SEC_IN_MS);
    },
    async beforeDestroy() {
      if (this.isEnabled) {
        clearInterval(this.heartbeatInterval);
        clearInterval(this.inactivityInterval);

        await this.sendDPTActivity();
      }
    },
    methods: {
      async sendDPTActivity() {
        // Only bother sending the activity if the accountUuid is set
        if (this.accountUuid) {
          const token = store.getters['user/auth/getAuth'].accessToken;

          if (this.hasCreatedActivity) {
            const activityHeartbeat = {
              end_time: new Date().toISOString(),
              active_duration: this.activityTimer.getTotalTime(),
            };

            await Vue.$http.put(
              `${URLS.THERAPIST_API}v1/dpt-activities/${this.activityUuid}/heartbeat`,
              activityHeartbeat,
              { headers: { Authorization: token } },
            );
          } else {
            const activity = {
              uuid: this.activityUuid,
              activity: activityName,
              account_uuid: this.accountUuid,
              start_time: this.startTime.toISOString(),
              end_time: new Date().toISOString(),
              active_duration: this.activityTimer.getTotalTime(),
            };

            await Vue.$http.post(
              `${URLS.THERAPIST_API}v1/dpt-activities`,
              activity,
              { headers: { Authorization: token } },
            );

            this.hasCreatedActivity = true;
          }
        }
      },
      extractSendbirdID(sendbirdId) {
        const [ , , ...swordId ] = sendbirdId.split('-');
        const idAsStr = swordId.join('-');
        const numId = Number(idAsStr);
        return Number.isNaN(numId) ? idAsStr : numId;
      },
    },
  };
}

<template>
  <div class="uploaded-file-preview">
    <file-avatar  :file-extension="fileData.extension"
                  :file-type="fileData.type"
                  :file-src="fileSrc">
    </file-avatar>
    <div class="-ml_1 uploaded-file-preview__text">
      <p class="compact-text">{{ fileData.name }}</p>
      <p class="t4 -t_heavy compact-text">{{ fileData.extension.toUpperCase() }}</p>
    </div>
    <sword-button-wuk icon="close"
                      theme="transparent"
                      round
                      :aria-label="`click to remove file ${fileData.name}.`"
                      @click.native="$emit('remove-file')">
    </sword-button-wuk>
  </div>
</template>

<script>

import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue';

import FileAvatar from '@/components/chat/conversation/FileAvatar.vue';

import { getFileData } from '@/scripts/utils';

export default {
  name: 'UploadedFilePreview',
  components: { SwordButtonWuk, FileAvatar },
  props: {
    file: {
      type: File,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fileData: {
        name: '',
        extension: '',
        type: '',
      },
      fileSrc: '',
    };
  },
  mounted() {
    this.fileData = getFileData(this.fileName, this.fileType);
    this.fileSrc = URL.createObjectURL(this.file);
  },
};
</script>

<style scoped lang="scss">
.uploaded-file-preview {
  display: flex;
  align-items: center;
  width: 18rem;
  background-color: $highlight-color;
  border-radius: 1rem;
  padding: 0.5rem 1.25rem 0.5rem 0.5rem;

  p {
    color: $white;
    margin: 0;

    &:first-child {
      margin-bottom: 0.25rem;
    }
  }

  &__text {
    min-width: 0;
    flex: 1;
  }

  .sword-button-wuk.--medium.round {
    margin: 0;
    min-width: initial;
    border: none;

    &:hover {
      background-color: initial;
    }

    ::v-deep {
      &.sword-icon.svg-icon {
        width: 1rem;
        height: 1rem;
        margin: auto;
      }
    }
  }
}
</style>

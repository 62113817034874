<template>
  <div class="chat-view">
    <loading-screen v-if="!ready('chat')" :show="true" />

    <div v-else class="chat-container">
      <h3 class="chat-header">{{ chatTitle }}</h3>

      <section class="chat-ui">
        <channel-list :class="{ 'hide-in-small-screens': hasActiveChannel }" />
        <div class="vertical-ruler" />
        <no-conversation-selected v-if="!hasActiveChannel" />
        <router-view v-else
                     :key="channelIdFromRoute"
                     class="chat-window"
                     :class="{ 'hide-in-small-screens': !hasActiveChannel }" />
      </section>
    </div>
    <timeout-manager v-if="ready('chat')" :activate="ready('chat')" @logout="logout" />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';

// Mixins
import connectionHandlers from '@/mixins/connection-handlers';
import channelEvents from '@/mixins/channel-events';
import sbUtils from '@/scripts/sendbird-integration/utils';

// Components
import ChannelList from '@/components/chat/ChannelList.vue';
import NoConversationSelected from '@/components/chat/conversation/NoConversationSelected.vue';
import LoadingScreen from '@/components/loaders/LoadingScreen.vue';
import TimeoutManager from '@/components/session-timeout/timeout-manager.vue';

export default {
  name: 'Chat',
  components: {
    ChannelList,
    LoadingScreen,
    NoConversationSelected,
    TimeoutManager,
  },
  mixins: [ connectionHandlers, channelEvents ],
  computed: {
    ...mapGetters({
      ready: 'status/ready',
      activeChannel: 'chat/activeChannel/getActiveChannel',
      members: 'chat/getMembers',
    }),
    channelIdFromRoute() {
      return this.$route.params && this.$route.params.channelId;
    },
    hasActiveChannel() {
      return this.activeChannel;
    },
    chatTitle() {
      return this.userHasPreventiveChannels ? 'Ask a PT chat room' : this.$t('copy_2479');
    },
    userHasPreventiveChannels() {
      const channels = (this.members?.length && this.members.map(({ channel }) => channel)) || [];
      return channels.some(sbUtils.isPreventiveChannel);
    },
  },
  async mounted() {
    document.addEventListener('visibilitychange', async () => {
      console.log('[bug-track] user changed tab');
      if (!document.hidden) {
        console.log('[bug-track] Chat tab got seleted');
        await this.$store.dispatch('user/handleCookieAuth');
      }
    });

    this.setLoading('chat');
    try {
      await this.connectToSendbird();
      this.initSendbirdCall();
      this.registerPushNotifications();
      // Creating all connection handlers
      this.createConnectionHandler(this.goToErrorPage);
      // Events handlers for user activity
      this.createChannelEvent();
    } catch (error) {
      this.goToErrorPage();
    }
    this.setReady('chat');
  },
  methods: {
    ...mapMutations({
      setLoading: 'status/setLoading',
      setReady: 'status/setReady',
    }),
    ...mapActions({
      connectToSendbird: 'chat/connectToSendbird',
      initSendbirdCall: 'call/initSendbirdCall',
      registerPushNotifications: 'user/registerPushNotifications',
      logout: 'user/auth/logout',
    }),
    goToErrorPage() {
      this.$router.push({ name: 'Error' });
    },
  },
};
</script>

<style lang="scss">
.chat-view {
  height: 100%;
  width: 100%;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 2.375em;
  box-sizing: border-box;

  @media screen and (max-width: $max-tablet-size) {
    padding-top: 0;
  }
}

.chat-ui {
  display: flex;
  flex-basis: 100%;
  height: auto;
  width: 100%;
  overflow-y: hidden;
  margin-top: $main-padding;

  @media screen and (max-width: $max-tablet-size) {
    margin-top: 0
  }
}

.chat-header {
  margin: 0;
  margin-left: $main-padding;
  color: getColor($greys, 1);

  @media screen and (max-width: $max-tablet-size) {
    display: none;
  }
}

.chat-window {
  padding: 0 30px 30px;

  @media screen and (max-width: $max-tablet-size) {
    padding: 0;
  }
}
</style>

<template>
  <div class="timer fc-blue-8">{{ display }}</div>
</template>
<script>
import { SESSION_TIMEOUT_CONFIG, AUTH_COOKIE } from '@/configs/constants';
import Cookies from 'js-cookie';

export default {
  name: 'timer',
  props: {
    duration: {
      type: Number,
      // default 5 minutes
      default: 60 * 15,
    },
    events: {
      type: Array,
      default: () => [ 'mousemove', 'keypress' ],
    },
    reminders: {
      type: Array,
      // array of seconds
      // emit "remind" event on each second
      default: () => [],
    },
  },
  data() {
    return {
      display: '',
      timer: null,
      countdown: null,
      minutes: '',
      seconds: '',
      remindersPassed: [],
      internalStartTimestamp: null,
    };
  },
  computed: {
    sortedReminders() {
      return [ ...this.reminders ].sort((a, b) => a - b);
    },
    timeUntilFirstReminder() {
      return (this.duration - this.firstReminder) * 1000;
    },
    firstReminder() {
      return this.sortedReminders[this.sortedReminders.length - 1];
    },
  },
  mounted() {
    this.setupNewTimer();
    this.$nextTick(() => {
      for (let i = this.events.length - 1; i >= 0; i -= 1) {
        window.addEventListener(this.events[i], this.setupNewTimer);
      }
    });
  },
  beforeDestroy() {
    this.resetCountdown();
    for (let i = this.events.length - 1; i >= 0; i -= 1) {
      window.removeEventListener(this.events[i], this.setupNewTimer);
    }
  },
  methods: {
    secondsCountdown() {
      // seconds since start
      /* eslint-disable no-bitwise */
      return this.duration - (((Date.now() - this.internalStartTimestamp) / 1000) | 0);
    },
    getStartTimestamp() {
      return Cookies.get(SESSION_TIMEOUT_CONFIG.sessionTimerStartCookieName);
    },
    setStartTimestamp(value) {
      this.internalStartTimestamp = value;
      Cookies.set(SESSION_TIMEOUT_CONFIG.sessionTimerStartCookieName, value, {
        domain: AUTH_COOKIE.token_domain,
      });
    },
    setTimerDisplay() {
      const timerCountdown = this.secondsCountdown();
      if (timerCountdown <= 0) {
        this.onIdle();
        return;
      }
      this.shouldRemind(timerCountdown);

      // bitwise OR to handle parseInt
      const minute = (timerCountdown / 60) | 0;
      const second = timerCountdown % 60 | 0;

      this.minutes = `${minute < 10 ? `0${minute}` : minute}`;
      this.seconds = `${second < 10 ? `0${second}` : second}`;

      this.display = `${this.minutes}:${this.seconds}`;
      this.$emit('change', this.display);
      this.scheduleTimeout(1000);
    },
    shouldRemind(timerCountdown) {

      if (this.reminders.length > 0) {
        if (this.reminders.includes(timerCountdown)) {
          this.emitReminder(timerCountdown);
          return;
        }
        this.checkIfShouldHaveBeenReminded(timerCountdown);
      }
    },
    // since the setInterval/setTimeout can be postponed when the tab is idle
    // we should check if reminder has already passed
    checkIfShouldHaveBeenReminded(timerCountdown) {
      const previousReminder = this.sortedReminders.find(reminder => timerCountdown < reminder);
      if (previousReminder && !this.remindersPassed.includes(previousReminder)) {
        this.emitReminder(previousReminder);
      }
    },

    checkActivity() {
      const lastActivityTimestamp = +this.getStartTimestamp();
      if (this.internalStartTimestamp < lastActivityTimestamp) {
        this.internalStartTimestamp = lastActivityTimestamp;
        this.onReset();
        this.scheduleTimeout(this.timeUntilFirstReminder - Date.now() - this.internalStartTimestamp);
        return;
      }

      this.setTimerDisplay();
    },
    onIdle() {
      this.$emit('idle');
      this.resetCountdown();
    },
    emitReminder(diff) {
      this.$emit('remind', diff);
      this.remindersPassed.push(diff);
    },
    onReset() {
      this.$emit('reset');
      this.resetCountdown();
    },
    scheduleTimeout(duration) {
      clearTimeout(this.countdown);
      this.countdown = setTimeout(this.checkActivity.bind(this), duration);
    },
    setupNewTimer() {
      if (this.countdown) {
        this.resetCountdown();
      }
      this.scheduleTimeout(this.timeUntilFirstReminder);
      this.setStartTimestamp(Date.now());
    },
    resetCountdown() {
      clearTimeout(this.countdown);
      this.remindersPassed = [];
    },
  },
};
</script>

import { ACTIVE_STATUS, PAUSED_STATUS, ONE_SEC_IN_MS } from './constants';

/**
 * Timer object for keeping track of user's activity and inactivity time on the page
 */
class Timer {
  constructor() {
    this.totalTimeInMs = 0;
    this.state = PAUSED_STATUS;
    this.elapsedTime = undefined;
    this.timerInterval = undefined;
  }

  start() {
    if (this.state === PAUSED_STATUS) {
      this.state = ACTIVE_STATUS;
      this.elapsedTime = new Date();

      if (!this.timerInterval) {
        this.timerInterval = setInterval(() => {
          const newElapsedTime = new Date();
          const diffInElapsedTime = newElapsedTime - this.elapsedTime;

          this.elapsedTime = newElapsedTime;
          this.totalTimeInMs += diffInElapsedTime;
        }, ONE_SEC_IN_MS);
      }
    }
  }

  pause() {
    // Add the time elapsed since the interval was called to totalTime
    if (this.elapsedTime !== undefined) {
      const newElapsedTime = new Date();
      const diffInElapsedTime = newElapsedTime - this.elapsedTime;

      this.elapsedTime = undefined;
      this.totalTimeInMs += diffInElapsedTime;
    }

    this.state = PAUSED_STATUS;
    clearInterval(this.timerInterval);
    this.timerInterval = undefined;
  }

  reset() {
    this.totalTimeInMs = 0;
    this.pause();
  }

  restart() {
    this.totalTimeInMs = 0;
    this.start();
  }

  getState() {
    return this.state;
  }

  getTotalTime() {
    return Math.floor(this.totalTimeInMs / ONE_SEC_IN_MS);
  }
}

export default Timer;

<template>
  <div class="conversation-header">
    <back-button class="back-btn" @click="back" />
    <profile-picture
        class="chat-user-photo"
        side="36px"
        :picture="picture"
        :gender="gender" />
    <div class="text-info">
      <h6 v-if="isPreventive"
          class="channel-name -t_medium fc-blue-8"
          :class="{ show: channelName }">
       <p class="preferred-name -t_heavy" v-if="preferredName"> {{ preferredName }} </p>
       {{ channelName || '_____________' }}
      </h6>
      <button v-else class="button-link" @click="$emit('handle-channel-name-click')">
        <h6
            class="channel-name -t_medium fc-blue-8"
            :class="{ show: channelName }">
          <p class="preferred-name -t_heavy" v-if="preferredName"> {{ preferredName }} </p>
          {{ channelName || '_____________' }}
          <template v-if="hasAutomaticChatMessagesOn === false">
            <span class="fc-blue-4"> | </span>
            <span class="t4 fc-red">{{ $t('CHAT.AUTOMATIC_MESSAGING.LABEL.OFF') }}</span>
          </template>
        </h6>
      </button>
      <span class="channel-status-dot" :class="online ? 'active' : ''" :title="online ? $t('CHAT.ONLINE') : $t('CHAT.AWAY')"></span>

      <div>
        <span v-if="localTime && !isPreventive" class="chat-user-local-time t4 fc-blue-4">
            {{ localTime }} {{ $t('CHAT.LOCAL_TIME') }}
        </span>
        <span v-else class="chat-user-local-time t4 fc-blue-4">
          {{ $t('CHAT.LOCAL_TIME_NOT_AVAILABLE') }}
        </span>
        <span class="copy-url-text" v-if="publicCallRoomToken">
          <span class="-mx_2">|</span>
          <a
            v-on:click="copyPublicUrlChatLink">
            <sword-icon-wuk name="copy-link" class="icon" />
            {{ $t('video_call_link') }}
          </a>
        </span>
      </div>
    </div>
    <div class="conversation-header__actions">
      <sword-button-wuk v-if="videoCallRoomId"
                        :icon="'camera'"
                        theme="transparent"
                        size="medium"
                        round
                        @click.native="goToGroupCall">
      </sword-button-wuk>
      <options-menu-wuk v-if="!isProdEnv && !isPreventive"
                        :options="options"
                        @option-clicked="handleOptionSelection">
      </options-menu-wuk>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue';
import ProfilePicture from '@ui-kit/components/pictures/ProfilePicture.vue';
import OptionsMenuWuk from '@ui-kit/components/menus/OptionsMenu.vue';
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue';
import SwordIconWuk from '@ui-kit/components/icons/SwordIcon.vue';

import Configs from '@/configs';

const EVENT_ACTIONS = {
  TOGGLE_AUTOMATIC_CHAT_MESSAGES: 'toggle-automatic-chat-messages',
};

export default {
  name: 'ConversationHeader',
  components: {
    BackButton,
    OptionsMenuWuk,
    ProfilePicture,
    SwordButtonWuk,
    SwordIconWuk,
  },
  data() {
    return {
      baseUrl: Configs.get('BASE_URL'),
    };
  },
  props: {
    picture: {
      type: String,
    },
    gender: {
      type: String,
      default: 'unknown',
    },
    channelName: {
      type: String,
      default: null,
    },
    preferredName: {
      type: String,
      default: null,
    },
    videoCallRoomId: {
      type: String,
      default: null,
    },
    publicCallRoomToken: {
      type: String,
      default: null,
    },
    online: {
      type: Boolean,
      default: false,
    },
    isPreventive: {
      type: Boolean,
      default: false,
    },
    hasAutomaticChatMessagesOn: {
      type: Boolean,
      default: null,
    },
    localTime: {
      type: String,
      default: null,
    },
  },
  computed: {
    options() {
      const array = [];
      if (this.hasAutomaticChatMessagesOn !== null) {
        array.push({
          optLabel: this.hasAutomaticChatMessagesOn ? 'CHAT.AUTOMATIC_MESSAGING.OFF' : 'CHAT.AUTOMATIC_MESSAGING.ON',
          optValue: EVENT_ACTIONS.TOGGLE_AUTOMATIC_CHAT_MESSAGES,
        });
      }
      return array;
    },
    /* TEMPORARY STRATEGY TO HIDE AUTOMATIC MESSAGES TOGGLE IN PRODUCTION */
    isProdEnv() {
      const prodUrls = [
        'https://chat.us.swordhealth.com',
        'https://chat.au.swordhealth.com',
        'https://chat.ca.swordhealth.com',
        'https://chat.eu.swordhealth.com',
        'https://chat.uk.swordhealth.com',
      ];
      return prodUrls.includes(this.baseUrl);
    },
  },
  methods: {
    back() {
      this.$store.dispatch('chat/activeChannel/deleteActiveChannel');
      this.$router.push({ name: 'Chat' });
    },
    goToGroupCall() {
      const route = this.$router.resolve({ name: 'GroupCallRoom', params: { roomId: this.videoCallRoomId } });
      window.open(route.href, '_blank');
    },
    async copyPublicUrlChatLink() {
      try {
        await navigator.clipboard.writeText(`${this.baseUrl}/call/public/${this.publicCallRoomToken}`);
        this.$notify.show('Video call link copied');
      } catch {
        console.log('Failed to copy link to clipboard');
      }
    },
    handleOptionSelection(eventAction) {
      this.$emit(eventAction, !this.hasAutomaticChatMessagesOn);
    },
  },
};
</script>

<style scoped lang="scss">
.conversation-header {
  text-align: center;
  padding: 0 0.5em 0.5em;
  border-bottom: 1px solid $auxiliar-color;
  display: grid;
  grid-template-columns: 5rem 1fr 5rem;;

  @media screen and (max-width: $max-tablet-size) {
    border-width: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 -2px 16px 0 $shadow-darker;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    .sword-button-wuk.--medium.round {
      margin: 0;
      min-width: initial;
      border: none;
      color: $black;

      &:hover {
        color: initial;
      }

      ::v-deep {
        &.sword-icon.svg-icon {
          width: 1.25rem;
          height: 1.25rem;
          margin: auto;
        }
      }
    }

    .options-menu-wrapper {
      ::v-deep {
        .options-menu-button__icon {
          width: 1.25rem;
          height: 1.25rem;
          margin: auto;
        }

        .options-menu-container .options-list-item {
          flex-direction: initial;
          justify-content: initial;
        }
      }
    }
  }

  .text-info {
    text-align: center;
    position: relative;
    grid-column-start: 2;

    @media screen and (max-width: $max-tablet-size) {
      flex: 1;
      text-align: left;
    }

    .copy-url-text {
      cursor: pointer;
      color: $context-color-5;
      @extend %-t_medium;
      @extend %t4;
      line-height: 16px;

      &:hover {
        text-decoration: underline;
      }

      a {
        color: $context-color-5;
      }

      .icon {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }
}

.chat-user-local-time,
.channel-name,
.channel-status {
  margin: 0;
}

.channel-name {
  display: flex;
  align-items: center;
  line-height: 1.5;

  @extend %placeholder-text;

  &.show {
    @extend %reset-placeholder-text;
  }
}

.preferred-name {
  margin:0 0.5rem 0 0;
  display: flex;
  align-items: center;
  &:after{
    content: '';
    width: 0.1875rem;
    height: 0.1875rem;
    background: $context-color-4;
    border-radius: 50%;
    margin:0 0 0 0.5rem;
  }
}

.chat-user-local-time,
.channel-status {
  @extend %t4;
  line-height: 1.33333;
  color: getColor($greys, 5);

  &.-active {
    color: $online-green;
  }
}

.channel-status-dot {
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 50%;
  margin-left: 0.25rem;
  background-color: getColor($greys, 5);
  display: inline-block;
  &.active {
    background-color: $online-green;
  }
}

.chat-user-photo {
  margin: 0px 10px;

  @media screen and (max-width: $max-tablet-size) {
    text-align: left;
  }
}

.back-btn {
  padding: 0;
}

.back-btn,
.chat-user-photo {
  display: none;

  @media screen and (max-width: $max-tablet-size) {
    display: block;
  }
}
</style>

<template>
  <div class="--admin">
    -- Admin Message --
  </div>
</template>

<script>

/* REPLICATE TYPE SPECIFIC OPTIONS FROM MESSAGE.JS */

export default {
  name: 'AdminMessage',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped lang="scss">
</style>

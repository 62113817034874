import store from '@/store';
import SendBirdSyncManagerEvent from '@/scripts/sendbird-integration/SendBirdSyncManagerEvent';
import { mergeFailedWithSuccessful } from '@/scripts/sendbird-integration/sendbird-utils';
import { MESSAGE_STATUS } from '@/configs/constants';

const _messageEventHandler = (messageCollection, channel, eventStatus, messages, action, reason) => {
  console.log(`[message-events] ${action} ${messages.length} messages on channel ${channel.url}${reason ? ', with the reason ' : ''}${reason || ''}`);
  switch (action) {
    case 'insert': {
      const wholeCollectionMessages = mergeFailedWithSuccessful(messageCollection.unsentMessages, messageCollection.succeededMessages);
      store.dispatch('chat/activeChannel/setChannelMessages', { channel, messages: wholeCollectionMessages });
      break;
    }
    case 'update': {
      // When a patient edits a message
      if (eventStatus === MESSAGE_STATUS.SUCCEEDED) {
        store.dispatch('chat/activeChannel/updateMessages', { channel, messages });
      }
      break;
    }
    case 'remove': {
      // When a patient removes a message
      if (eventStatus === MESSAGE_STATUS.SUCCEEDED) {
        store.dispatch('chat/activeChannel/removeMessages', { channel, messages });
      }
      break;
    }
    case 'clear':
    default: {
      break;
    }
  }
};

const _onNewMessageEventHandler = (channel, message) => {
  console.log('[message-events] message received: ', channel, message);
};

export default {
  methods: {
    createMessageCollectionEvent(channel) {
      const messageCollectionEvent = new SendBirdSyncManagerEvent(channel);
      console.log('[events] setting up message collection events');

      messageCollectionEvent.onSucceededMessageEvent = (messageCollection, messages, action, reason) => {
        console.log(`[message-events] ${messages.length} succeeded messages`, channel);
        _messageEventHandler(messageCollection, channel, MESSAGE_STATUS.SUCCEEDED, messages, action, reason);
      };
      messageCollectionEvent.onPendingMessageEvent = (messageCollection, messages, action, reason) => {
        console.log(`[message-events] ${messages.length} pending messages`, channel);
        _messageEventHandler(messageCollection, channel, MESSAGE_STATUS.PENDING, messages, action, reason);
      };
      messageCollectionEvent.onFailedMessageEvent = (messageCollection, messages, action, reason) => {
        console.log(`[message-events] ${messages.length} failed  messages`, channel);
        console.log('[message-events] failed messages: ', channel, messages);
        _messageEventHandler(messageCollection, channel, MESSAGE_STATUS.FAILED, messages, action, reason);
      };
      messageCollectionEvent.onNewMessage = (messageCollection, message) => {
        console.log('[message-events] new message', channel, message);
        _onNewMessageEventHandler(channel, message);
      };
    },
  },
};

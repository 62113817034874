<template>
  <div class="channel-list-container">
    <input v-model="search"
           data-testid="input_searchPatients"
           type="text"
           class="search-input t2"
           :placeholder="`${$t('COMMON.SEARCH')} ${$t('COMMON.PATIENTS').toLowerCase()}`">

    <ul class="channel-filter-tabs -mx_4 -my_3">
      <li class="-t_medium -mx_2 -pb_1"
          v-for="filterTab in channelsTabs"
          :key="filterTab.key"
          :class="{ 'selected': selectedFilter === filterTab.key }"
          @click="selectFilter(filterTab.key)">
        {{ $t(filterTab.label) }} {{ filterTab.counter }}
      </li>
    </ul>
    <div ref="channelList"
         data-testid="list_channels"
         class="channel-list"
         @scroll.passive="handleScrollDown">
      <!-- Channel filters tabs -->
      <pulse-loader-v2 v-if="loading('channels')"></pulse-loader-v2>
      <ul v-else>
        <chat-item v-for="(member, key) in parsedMembers"
                   :key="`chat-item-${key}`"
                   :member="member"
                   :member-list="member.otherMembersInSameChannel"
                   :is-selected="selectedChatUrl === member.url"
                   @click.native="(ev) => chatSelected(ev, member)" />
      </ul>
    </div>
  </div>
</template>

<script>
import { tools } from '@sword-health/ui-core';

import { mapGetters, mapMutations, mapActions } from 'vuex';
import sbUtils from '@/scripts/sendbird-integration/utils';
import { MESSAGES_TYPES } from '@/configs/constants';

// Components
import PulseLoaderV2 from '@ui-kit/components/loaders/PulseLoaderV2.vue';

import ChatItem from '@/components/chat/ChatItem.vue';

export default {
  name: 'ChannelList',
  components: { ChatItem, PulseLoaderV2 },
  data() {
    return {
      search: '',
      selectedChatUrl: null,
      refreshInterval: null,
      selectedFilter: 'all',
    };
  },
  computed: {
    ...mapGetters({
      members: 'chat/getMembers',
      channels: 'chat/getChannels',
      counters: 'chat/getCounters',
      selectedPage: 'chat/getSelectedPage',
      loading: 'status/loading',
    }),
    channelIdFromRoute() {
      return this.$route.params && this.$route.params.channelId;
    },
    parsedMembers() {
      const members = this.members.map(member => {

        const { channel, patient } = member;
        let patientInfo = {};


        if (patient) {
          patientInfo = {
            institution: patient.institution_name,
            picture: patient.picture,
            gender: patient.gender,
            hasApp: patient.has_patient_app_installed,
          };
        }

        let otherMembersInSameChannel = [];
        if (sbUtils.isPreventiveChannel(channel)) {
          otherMembersInSameChannel = channel.members.filter(m => m.userId !== member.userId);
        }

        return {
          ...member,
          lastMessage: channel && channel.lastMessage,
          unreadMessageCount: channel && channel.unreadMessageCount,
          url: channel?.url,
          otherMembersInSameChannel,
          ...patientInfo,
        };
      });
      return members;
    },
    channelsTabs() {
      return {
        ALL: {
          key: MESSAGES_TYPES.ALL,
          label: 'CHAT_ALL',
          counter: this.counters[MESSAGES_TYPES.ALL],
        },
        UNREAD: {
          key: MESSAGES_TYPES.UNREAD,
          label: 'CHAT_UNREAD',
          counter: this.counters[MESSAGES_TYPES.UNREAD],
        },
        PENDING: {
          key: MESSAGES_TYPES.PENDING,
          label: 'CHAT_PENDING',
          counter: this.counters[MESSAGES_TYPES.PENDING],
        },
      };
    },
  },
  watch: {
    search: tools.debounce(async function getFilteredChannels(newVal) {
      this.setLoading('channels');
      if (newVal) {
        await this.fetchChannelList({ isInit: true, nameFilter: newVal, messagesType: this.selectedFilter });
      } else {
        await Promise.all([
          this.fetchChannelList({ isInit: true, messagesType: this.selectedFilter }),
          this.fetchAllMessagesCount(),
          this.fetchUnreadMessagesCount(),
          this.fetchPendingMessagesCount(),
        ]);
      }
      this.setReady('channels');
    }, 500),
  },
  mounted() {
    this.loadChannelList();
    const MINUTES_TO_REFRESH = 5;
    const MILLISECONDS_TO_REFRESH = MINUTES_TO_REFRESH * 60 * 1000;
    this.refreshInterval = setInterval(() => {
      this.refreshChannelList();
    }, MILLISECONDS_TO_REFRESH);
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
  },
  methods: {
    ...mapMutations({
      setLoading: 'status/setLoading',
      setReady: 'status/setReady',
      setSelectedPage: 'chat/setSelectedPage',
    }),
    ...mapActions({
      setActiveChannelByUrl: 'chat/activeChannel/setActiveChannelByUrl',
      fetchChannelList: 'chat/fetchChannelList',
      refreshChannelList: 'chat/refreshChannelList',
      fetchAllMessagesCount: 'chat/fetchAllMessagesCount',
      fetchUnreadMessagesCount: 'chat/fetchUnreadMessagesCount',
      fetchPendingMessagesCount: 'chat/fetchPendingMessagesCount',
      resetTabCounter: 'chat/resetTabCounter',
    }),
    async selectFilter(filterKey) {
      if (this.selectedFilter === filterKey) {
        return;
      }
      this.selectedFilter = filterKey;
      this.setLoading('channels');
      this.resetTabCounter(this.selectedFilter);
      await this.setSelectedPage(1);
      await this.fetchChannelList({ isInit: true, nameFilter: this.search, messagesType: this.selectedFilter });
      this.setReady('channels');
    },
    async loadChannelList() {
      this.setLoading('channels');
      await Promise.all([
        this.fetchChannelList({ merge: true, messagesType: this.selectedFilter }),
        this.fetchAllMessagesCount(),
        this.fetchUnreadMessagesCount(),
        this.fetchPendingMessagesCount(),
      ]);

      if (!this.selectedChatUrl && this.channelIdFromRoute) {
        this.selectedChatUrl = this.channelIdFromRoute;
        this.setActiveChannelByUrl(this.channelIdFromRoute);
      }

      this.setReady('channels');
    },
    handleScrollDown() {
      if (this.channels.length === this.counters[this.selectedFilter]) {
        return;
      }

      tools.debounce(() => {
        const { clientHeight, scrollHeight, scrollTop } = this.$refs.channelList;
        const isScrollBottom = clientHeight + scrollTop + 200 >= scrollHeight; // adjust to fetch when NEAR the bottom

        if (isScrollBottom) {
          this.setSelectedPage(this.selectedPage + 1);
          this.fetchChannelList({ merge: true, messagesType: this.selectedFilter });
        }
      }, 500)();
    },
    chatSelected(ev, chat) {
      this.selectedChatUrl = chat.url;
      this.chatItemClickHandler(chat.url);
    },
    async chatItemClickHandler(channelUrl) {
      try {
        const channel = await this.setActiveChannelByUrl(channelUrl);
        if (!this.channelIdFromRoute || this.channelIdFromRoute !== channel.url) {
          this.$router.push({ name: 'Channel', params: { channelId: channel.url } });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.channel-list-container {
  display: flex;
  flex-basis: 400px;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  overflow-y: hidden;

  @media screen and (max-width: $max-tablet-size) {
    flex-basis: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.channel-filter-tabs {
  display: flex;

  li {
    text-align: center;
    cursor: pointer;

    &:not(.selected):hover {
      border-bottom: 2px solid getColor($blues-wuk, 3);
    }

    &.selected {
      border-bottom: 2px solid $highlight-color;
      color: $highlight-color;
    }
  }
}

.channel-list {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  height: 100%;

  @media screen and (max-width: $max-tablet-size) {
    overflow-y: visible;
  }

  .pulse-loader {
    position: absolute;
    left: 50%;
    top: calc(50% - 3rem);
  }
}

.search-input {
  width: auto;
  margin: 0 $main-padding;
  padding: 1em 0 1em 2.25em;
  background: svg-load('./assets/svg-icons/search.svg', fill=#{$context-color-4}) left center no-repeat;
  background-size: 20px;
  border: 0;
  border-bottom: 1px solid getColor($greys, 5);;
  box-sizing: border-box;
  outline: none;

  @media screen and (max-width: $max-tablet-size) {
    margin: 0 $main-mobile-padding;
    padding: 0.75em 1em 0.75em 2.55em;
    background: svg-load('./assets/svg-icons/search.svg', fill=#{$context-color-4}) 0.75em center no-repeat, getColor($greys, 7);
    background-size: 1.25em;
    border-width: 0;
    border-radius: 20px;
    font-size: 1em;
  }

  &::placeholder {
    // Chrome, Firefox, Opera, Safari 10.1+
    color: getColor($greys, 4);
    opacity: 1; // Firefox
  }

  &:-ms-input-placeholder {
    // Internet Explorer 10-11
    color: getColor($greys, 4);
  }

  &::-ms-input-placeholder {
    // Microsoft Edge
    color: getColor($greys, 4);
  }
}
</style>

<template>
  <div class="channel-message-container">
    <div class="message-wrapper" :class="{'--from-self': fromSelf, '--from-pt': fromPT}">

      <!-- Scheduled SMS status / Cancelation -->
      <info-tooltip-wuk v-if="smsScheduled"
                        class="sms-state -mr_2"
                        :show-icon="false"
                        position="center"
                        side="top">
        <template v-slot:tooltip-trigger>
          <div class="sms-state-annotations">
            <sword-icon v-if="[SMS_SCHEDULING_STATUS.PROCESSED, SMS_SCHEDULING_STATUS.SENT_AUTOMATIC].includes(smsSchedulingInfo.currentStatus)"
                        name="envelope-sent"
                        class="sms-state-annotations__state-icon --sent" />
            <div v-else-if="smsSchedulingInfo.currentStatus === SMS_SCHEDULING_STATUS.SCHEDULED"
                 @click="cancelSmsReplicaRequest">
              <sword-icon name="envelope-pending"
                          class="sms-state-annotations__state-icon" />
              <sword-icon name="close-cross"
                          class="sms-state-annotations__cancel-button" />
            </div>
          </div>
        </template>
        <p class="-mxy_0" @click.stop>{{ $t(SMS_SCHEDULED_COPIES[smsSchedulingInfo.currentStatus]) }}</p>
      </info-tooltip-wuk>

      <profile-picture v-if="!fromSelf"
                       class="user-photo"
                       :class="{ omit: omitAvatar }"
                       :side="isMobile ? '30px' : '40px'"
                       :picture="member.picture"
                       :gender="member.gender || 'unknown'" />

      <p class="message-sender" v-if="!fromSelf && showName">{{ senderName }}</p>

      <div class="message-content">

        <!-- Send via SMS button -->
        <info-tooltip-wuk v-if="smsSchedulingEnabled"
                          class="send-via-sms"
                          :show-icon="false"
                          side="top">
          <template v-slot:tooltip-trigger>
            <button class="send-via-sms__button"
                    :disabled="smsSchedulingInfo.smsBlocked || member.canReceiveSms === false"
                    @click="toggleScheduleSmsStatus">
              <sword-icon name="envelope" />
            </button>
          </template>
          <p class="-mxy_0" @click.stop>
            {{ $t(toggleScheduleSmsStatusCopyKey) }}
          </p>
        </info-tooltip-wuk>

        <div class="message-content__body">
          <admin-message v-if="messageType === MESSAGES_TYPES.ADMIN" />
          <call-message v-else-if="messageType === MESSAGES_TYPES.CALL" />
          <file-message v-else-if="messageType === MESSAGES_TYPES.FILE"
                        :message="message" />
          <text-message v-else-if="messageType === MESSAGES_TYPES.TEXT"
                        :message="message.message" />
        </div>

        <div class="message-content__meta">
          <span v-show="message.updatedAt" class="t3 edited-text">({{ $t('copy_4703') }})</span>

          <sh-icon v-if="sent"
                   class="sent-icon"
                   name="check"/>
        </div>
      </div>
    </div>

    <p v-if="failed" class="message-failed-feedback t3">
      {{ $t('FEEDBACK.YOUR_MESSAGE_WAS_NOT_SENT1') }}
    </p>
  </div>
</template>

<script>
// Sendbird tools
import SendBirdAction from '@/scripts/sendbird-integration/SendBirdAction';

// Configs
import { MESSAGE_STATUS, IS_MOBILE_WIDTH } from '@/configs/constants';
import { SMS_SCHEDULING_STATUS, SMS_SCHEDULING_COPIES, SMS_SCHEDULED_COPIES } from '@/scripts/sms-scheduling-status';
import MESSAGES_TYPES from '@/scripts/message-types';
import sbUtils from '@/scripts/sendbird-integration/utils';

// Components
import AdminMessage from '@/components/chat/messages/AdminMessage.vue';
import CallMessage from '@/components/chat/messages/CallMessage.vue';
import FileMessage from '@/components/chat/messages/FileMessage.vue';
import TextMessage from '@/components/chat/messages/TextMessage.vue';
import ProfilePicture from '@ui-kit/components/pictures/ProfilePicture.vue';
import SwordIcon from '@ui-kit/components/icons/SwordIcon.vue';

import infoTooltipWuk from '@ui-kit/components/tooltips/infoTooltipWuk.vue';

export default {
  name: 'Message',
  components: {
    AdminMessage,
    CallMessage,
    FileMessage,
    TextMessage,
    ProfilePicture,
    SwordIcon,
    infoTooltipWuk,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
    smsSchedulingInfo: {
      type: Object,
      default: () => ({}),
    },
    omitAvatar: {
      type: Boolean,
      required: true,
    },
    showName: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      MESSAGES_TYPES,
      SMS_SCHEDULING_STATUS,
      SMS_SCHEDULING_COPIES,
      SMS_SCHEDULED_COPIES,
    };
  },
  methods: {
    cancelSmsReplicaRequest() {
      this.$emit('cancel-schedule-sms', this.message, this.smsSchedulingInfo.notificationType);
    },
    toggleScheduleSmsStatus() {
      if (this.smsSchedulingInfo.currentStatus === this.SMS_SCHEDULING_STATUS.SCHEDULED) {
        this.cancelSmsReplicaRequest();
        return;
      }
      this.$emit('send-via-sms', this.message);
    },
  },
  computed: {
    toggleScheduleSmsStatusCopyKey() {
      if (this.member.canReceiveSms === false) {
        return this.$t('webchat_sidebar_profile.number_not_eligible_sms');
      }
      if (this.smsSchedulingInfo.smsBlocked) {
        return this.SMS_SCHEDULING_COPIES.USER_BLOCKED_SMS;
      }
      if (this.smsSchedulingInfo.currentStatus === this.SMS_SCHEDULING_STATUS.SCHEDULED) {
        return this.SMS_SCHEDULED_COPIES.SCHEDULED;
      }
      return this.SMS_SCHEDULING_COPIES.SCHEDULE;
    },
    smsSchedulingEnabled() {
      // Ensure retro compatibility
      const smsBlockFlagSet = this.smsSchedulingInfo.smsBlocked !== null;
      const smsAlreadyProcessed = [
          SMS_SCHEDULING_STATUS.PROCESSED,
          SMS_SCHEDULING_STATUS.SENT_AUTOMATIC,
        ].includes(this.smsSchedulingInfo.currentStatus);
      const memberRepliedAfter = this.smsSchedulingInfo.memberReplied === true;
      return this.fromPT && this.fromSelf
          && smsBlockFlagSet
          && this.message.customType !== 'automatic'
          && !smsAlreadyProcessed
          && !memberRepliedAfter;
    },
    smsScheduled() {
      return this.smsSchedulingInfo.currentStatus && this.smsSchedulingInfo.currentStatus !== SMS_SCHEDULING_STATUS.CANCELLED;
    },
    fromSelf() {
      const sendbirdAction = SendBirdAction.getInstance();
      return this.message.sender && sendbirdAction.isCurrentUser(this.message.sender);
    },
    fromPT() {
      return sbUtils.isPT(this.member);
    },
    senderName() {
      const { nickname } = this.member;
      const role = this.fromPT ? 'PT' : null;
      return role ? `${nickname} - ${role}` : nickname;
    },
    isMobile() {
      return window.innerWidth <= IS_MOBILE_WIDTH;
    },
    messageType() {
      if (this.message.isUserMessage()) {
        if (this.message.plugins.some(plugin => plugin.vendor === 'sendbird' && plugin.type === 'call')) {
          return this.MESSAGES_TYPES.CALL;
        }
        return this.MESSAGES_TYPES.TEXT;
      }
      if (this.message.isFileMessage()) {
        return this.MESSAGES_TYPES.FILE;
      }
      if (this.message.isAdminMessage()) {
        return this.MESSAGES_TYPES.ADMIN;
      }
      return null;
    },
    sent() {
      return this.message.sendingStatus === MESSAGE_STATUS.SUCCEEDED && this.fromSelf;
    },
    failed() {
      return this.message.sendingStatus === MESSAGE_STATUS.FAILED
          && this.message.messageId === 0
          && this.fromSelf;
    },
  },
};
</script>

<style scoped lang="scss">
.send-via-sms,
.sms-state {
  &::v-deep .info-content-box {
    max-width: 7rem;
    text-align: center;
  }
}

.send-via-sms {
  $send-via-sms-button-size: 2.5rem;

  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-1rem, -50%);
  height: auto;
  color: $context-color-1;
  display: none;


  // Dirty tmp fix for tooltip out of viewport
  &.info-tooltip-container::v-deep {
    .info-content-box::before {
      left: 100% !important;
      transform: translateX(calc(calc(-100% - $send-via-sms-button-size/2) + 3px));
    }
  }

  &__button {
    width: $send-via-sms-button-size;
    height: $send-via-sms-button-size;
    border-radius: 50%;
    display: flex;
    align-items: center;
    border: none;
    justify-content: center;
    background-color: white;
    box-shadow: 0 4px 12px 0 rgb(29 56 97 / 25%);

    &:hover:not(:disabled) {
      background-color: $context-color-6;
    }

    &:disabled {
      cursor: not-allowed;

      svg {
        fill: $context-color-5;
      }
    }

    svg {
      width: 1rem !important;
      height: 1rem !important;
    }
  }
}

.sms-state {
  align-self: end;
  grid-area: sms-state;

  &:not(:hover) {
    .sms-state-annotations__cancel-button {
      display: none;
    }
  }

  &-annotations {
    &__cancel-button.svg-icon {
      fill: $secondary-color;
      padding-bottom: 0.35rem;
    }

    &__state-icon.--sent {
      fill: $green;
      cursor: default;
    }
  }
}

.message-sender {
  @extend %t3;
  @extend .fc-blue-5;
  margin-top: 0;
  margin-bottom: map-get($sh-spacers-wuk, 1);
  margin-left: map-get($sh-spacers-wuk, 1);
  grid-area: username;
}

.avatar {
  $avatar-size: 40px;
  position: relative;
  margin-right: 0.5rem;

  .user-avatar {
    @include circle(40px, getColor($greys, 5));
  }
}

.user-photo {
  margin-right: 10px;
  grid-area: avatar;

  &.omit {
    visibility: hidden;
  }
}
</style>

<template>
  <div data-testid="text_noConversationSelected"
       class="hide-in-small-screens no-active-chat">
    Select a conversation to start chatting
  </div>
</template>

<script>
export default {
  name: 'NoConversationSelected',
};
</script>

<style lang="scss" scoped>
.no-active-chat {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<template>
  <button class="file-avatar">
    <component :is="previewComponent.tag" v-bind="previewComponent.props"></component>
  </button>
</template>

<script>
import SwordIcon from '@ui-kit/components/icons/SwordIcon.vue';

export default {
  name: 'FileAvatar',
  components: { SwordIcon },
  props: {
    fileExtension: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
    fileSrc: {
      type: String,
      default: '',
    },
  },
  computed: {
    previewComponent() {
      return this.getFilePreviewComponent(this.fileType, this.fileExtension, this.fileSrc);
    },
  },
  methods: {
   getFileIconName(type, extension) {
      const AVAILABLE_ICONS = [ 'audio', 'text' ];
      const ARCHIVE_FILE_TYPES = [ 'zip', 'zipx', '7z', 's7z', 'tar', 'rar' ];
      const OTHER_TEXT_FILES = [ 'doc', 'docx', 'docm' ];

      if (AVAILABLE_ICONS.includes(type)) {
        return type;
      }

      if (ARCHIVE_FILE_TYPES.includes(extension)) {
        return 'archive';
      }

      if (OTHER_TEXT_FILES.includes(extension)) {
        return 'text';
      }
      return 'other-file-types';
    },
    getFilePreviewComponent(type, extension, fileSrc) {
      if (type === 'image') {
        return { tag: 'img', props: { src: fileSrc } };
      }

      if (type === 'video') {
        return { tag: 'video', props: { src: fileSrc } };
      }

      return { tag: 'sword-icon', props: { name: this.getFileIconName(type, extension) } };
    },
  },
};
</script>

<style scoped lang="scss">

.file-avatar {
  border: none;
  background-color: $white;
  border-radius: 0.5rem;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: $context-color-6;
  }

  img, video {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: fill;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.75;
    }
  }

  .sword-icon.svg-icon {
    transform: scale(1.5);
    fill: $black;
  }
}

</style>

<template>
  <li class="chat-item" :class="{ 'selected': isSelected }">
    <div class="avatar">
      <profile-picture
        :picture="patient.picture"
        :high-risk="patient.highRisk"
        :gender="gender"
        size="3.125em"
      />
      <div v-if="patient.hasApp" class="status-indicator" :class="{ online: isOnline }" />
    </div>

    <div class="textual-content">
      <div class="row-content">
        <p data-testid="text_patientName" class="item-title">{{ patient.nickname }}</p>

        <div v-if="unreadCounter" class="unread-messages">{{ unreadCounter }}</div>
      </div>

      <div class="row-content">
        <span class="item-subtitle">
          <span v-if="member.patient?.current_state === 'discharged'" class="item-badge">
            {{ patient.currentState }}
          </span>
          <span v-if="showMemberList" data-testid="text_memberList" :class="{ 'loading': !memberNames }">
            {{ memberNames }}
          </span>
          <span v-else data-testid="text_institutionName" class="item-subtitle">{{ patient.institution }}</span>
        </span>


        <p v-if="!patient.hasApp" data-testid="text_noApp" class="item-subtitle no-app">
          {{ $t('copy_3008') }}
        </p>

        <p v-else class="item-subtitle">
          {{ lastMessageDate }}
        </p>
      </div>
    </div>
  </li>
</template>

<script>
import ProfilePicture from '@ui-kit/components/pictures/ProfilePicture.vue';

export default {
  name: 'ChatItem',
  components: { ProfilePicture },
  props: {
    member: {
      type: Object,
      required: true,
    },
    memberList: {
      type: Array,
      default: () => ([]),
      required: false,
    },
    isSelected: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    patient() {
      return {
        currentState: this.member.patient?.current_state,
        nickname: this.member.nickname || '',
        institution: this.member.institution || '',
        picture: this.member.picture || null,
        highRisk: !!this.member.patient?.patient_is_high_risk,
        hasApp: this.member.hasApp ?? true,
      };
    },
    showMemberList() {
      return this.memberList.length > 0;
    },
    memberNames() {
      const names = this.memberList.map(m => (m.nickname ? m.nickname.split(' ')[0] : ''));
      return names.length ? names.join(', ') : '';
    },
    gender() {
      const genderAPI = this.member.gender;
      const isSupportedGender = [ 'female', 'male', 'non-binary', 'non_binary' ].indexOf(genderAPI?.toLowerCase()) !== -1;
      return (genderAPI && isSupportedGender) ? genderAPI : 'unknown';
    },
    isOnline() {
      return this.member.connectionStatus === 'online';
    },
    lastMessageDate() {
      if (!this.member.lastMessage) {
        return '';
      }
      const lastDate = this.member.lastMessage.createdAt;
      return lastDate ? this.$date().lastMessage(lastDate) : '';
    },
    unreadCounter() {
      return this.member.unreadMessageCount > 99 ? '+99' : this.member.unreadMessageCount;
    },
  },
};
</script>

<style scoped lang="scss">
.chat-item {
  display: flex;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  padding: 1em $main-padding;

  @media screen and (max-width: $max-tablet-size) {
      padding: 1em $main-mobile-padding;
    }

  &:hover {
    background-color: getColor($greys, 7);
  }
  &.selected {
    background: getColor($greys, 6);

    .item-subtitle {
      color: getColor($greys, 1);
    }
  }
}

.textual-content {
  width: 100%;
  overflow: hidden;

  .row-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    *:nth-child(2) {
      flex-shrink: 0;
    }
  }
}

.avatar {
  $avatar-size: 3.125em;
  position: relative;
  flex: 0 0 $avatar-size;
  margin-right: 1em;

  .status-indicator {
    @include circle(12px, getColor($greys, 5));
    position: absolute;
    right: 0;
    bottom: 0;
    border: 2px solid white;

    &.online {
      background-color: $online-green;
    }
  }
}

.item-title {
  @extend %compact-text;
  @extend %-my_0;
  @extend %-t_medium;
  @extend .fc-blue-8;
  line-height: $line-height-base;
}

.item-badge {
  display: inline-flex;
  align-items: baseline;
  line-height: 1.7;
  background-color: $context-color-8;
  color: $context-color-3;
  padding: 0 0.6rem;
  margin-right: 0.3rem;
  border-radius: 0.3rem;
  text-transform: capitalize;
  font-weight: 500;
  font-size: $font-base-12;
}

.item-subtitle {
  @extend %compact-text;
  @extend %-my_0;
  @extend %t4;
  @extend .fc-blue-5;
  line-height: $line-height-base;
  &.no-app {
    color: $hard-red!important;
  }
}

.unread-messages {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: fit-content;
  min-width: 20px;
  padding: 0.15rem 0.3rem 0 0.3rem;
  background: $highlight-color;
  border-radius: 12px;
  color: white;
  font-size: 0.75rem;
  margin-left: 0.3rem;
}

.loading {
  height: 1em;
  width: 100%;
  margin: 0.25em 0;
  background: rgba(247, 249, 252, 1);
  animation: fading 1.4s ease-in-out infinite;

  &.t4 {
    height: 0.75em;
  }
}
</style>

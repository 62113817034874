<template>
  <transition name='modal'>
    <div  class='modal-backdrop'
          @click="$emit('handle-close')">
      <send-message :sending='sending'
                    :send-message-outcome='sendMessageOutcome'
                    :default-recipient='patientInfo'
                    :has-editable-recipient="false"
                    @send-message='sendMessage'
                    @close-modal="$emit('handle-close')">
      </send-message>
    </div>
  </transition>
</template>

<script>
import SendMessage from '@ui-kit/components/dt-messages/SendMessage.vue';

const MESSAGE_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export default {
  name: 'sendMessageModal',
  components: {
    SendMessage,
  },
  props: {
    patientInfo: {
      type: Object,
      required: true,
    },
  },
  emits: [ 'handle-close' ],
  data() {
    return {
      sending: false,
      sendMessageOutcome: '',
    };
  },
  computed: {
    activeInstitutionId() {
      return this.$store.getters['user/institutions/getActiveInstitutionId'];
    },
    patientsList() {
      const allPatients = this.$store.getters['patientsList/getAllPatientsList'];

      if (!allPatients) {
        return [];
      }

      return allPatients.map(patient => ({
        ...patient,
        name: `${patient.firstname} ${patient.lastname}`,
      }));
    },
  },
  methods: {
    async sendMessage(messageData) {
      try {
        const token = this.$store.getters['user/auth/getAuth'].accessToken;
        const professionalRefID = this.$store.getters['user/getUserId'];
        this.sending = true;
        this.sendMessageOutcome = '';
        await this.$http.post('api/v1/messages', { ...messageData, professionalRefID }, { headers: { Authorization: token } });
        this.sendMessageOutcome = MESSAGE_STATUS.SUCCESS;
        setTimeout(() => this.$emit('handle-close'), 1000);
      } catch (e) {
        this.sendMessageOutcome = MESSAGE_STATUS.ERROR;
        console.error(`It was not possible to send the message to member with id ${messageData}.`);
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>

<style lang='scss' scoped></style>

<template>
  <transition name="modal">
    <div  class="modal-backdrop"
          @click="shouldCloseWithoutSaving">
      <div :class="['notes-modal', { 'notes-modal--loading': isFetching || savingNote }]" @click.stop>
        <pulse-loader-v2 v-if="isFetching || savingNote"></pulse-loader-v2>
        <notes-overview v-else
                        ref="notes-overview"
                        :picture="patientInfo.picture"
                        :gender="patientInfo.gender"
                        :name="patientInfo.name"
                        :notes="patientNotes"
                        :saving-note="savingNote"
                        @should-close-without-saving="shouldCloseWithoutSaving"
                        @save-note="saveNote"
                        @delete-note="deleteNote">
        </notes-overview>
      </div>
    </div>
  </transition>
</template>

<script>
import NotesOverview from '@ui-kit/components/notes/NotesOverview.vue';
import PulseLoaderV2 from '@ui-kit/components/loaders/PulseLoaderV2.vue';

export default {
  name: 'notesModal',
  components: {
    NotesOverview,
    PulseLoaderV2,
  },
  props: {
    patientInfo: {
      type: Object,
      required: true,
    },
  },
  emits: [ 'handle-close' ],
  data() {
    return {
      savingNote: false,
      patientNotes: [],
      isFetching: false,
    };
  },
  methods: {
    async shouldCloseWithoutSaving() {
      let shouldSave = false;
      const currentNote = this.$refs['notes-overview']?.currentNote;

      this.$emit('handle-close');

      if (currentNote?.id) {
        const prevNote = this.patientNotes.find(note => note.id === currentNote.id);
        shouldSave = (prevNote && prevNote.title !== currentNote.title) || prevNote.body !== currentNote.body;
      } else {
        shouldSave = !!currentNote?.body;
      }

      if (shouldSave) {
        await this.saveNote(currentNote);
        this.$notify.success(this.$t('copy_2687'));
      } else {
       this.$refs['notes-overview'].closeOpenNote();
      }
    },
    formatLastEditDate(lastEditDate) {
      return this.$date(lastEditDate * 1000).format('DD MMM YYYY');
    },
    async saveNote(note) {
      const currentNote = note;
      this.savingNote = true;

      if (!currentNote.title) {
        currentNote.title = this.$t('copy_2676');
      }
      await (currentNote.id ? this.updateNote(currentNote) : this.createNote(currentNote));
      this.savingNote = false;
    },
    async fetchPatientNotes() {
      try {
        this.isFetching = true;
        const token = this.$store.getters['user/auth/getAuth'].accessToken;
        const { data: notes } = await this.$http.get(`api/v1.1/patients/${this.patientInfo.user_id}/notes`, { headers: { Authorization: token } });
        this.patientNotes = notes.map(note => ({
          ...note,
          formattedLastEditDate: this.formatLastEditDate(note.last_edit.date),
        }));
      } catch (e) {
        console.log(`[notes] error fetching notes of patient ${this.patientInfo.user_id}:`, e);
      } finally {
        this.isFetching = false;
      }
    },
    async createNote(noteToCreate) {
      try {
        const token = this.$store.getters['user/auth/getAuth'].accessToken;
        const { data: newNote } = await this.$http.post(
          `api/v1.1/patients/${this.patientInfo.user_id}/notes`, noteToCreate, { headers: { Authorization: token } },
        );
        this.patientNotes = [ { ...newNote, formattedLastEditDate: this.formatLastEditDate(newNote.last_edit.date) }, ...this.patientNotes ];
      } catch (e) {
        console.error(`[notes] error saving note of patient ${this.patientInfo.user_id}:`, e);
      }
    },
    async updateNote(noteToUpdate) {
      try {
        const token = this.$store.getters['user/auth/getAuth'].accessToken;
        const { data: updatedNote } = await this.$http.put(
          `api/v1.1/patients/${this.patientInfo.user_id}/notes/${noteToUpdate.id}`, noteToUpdate, { headers: { Authorization: token } },
        );
        const updatedNotes = [
          { ...updatedNote, formattedLastEditDate: this.formatLastEditDate(updatedNote.last_edit.date) },
          ...this.patientNotes.filter(note => note.id !== noteToUpdate.id),
        ];
        this.patientNotes = updatedNotes;
      } catch (e) {
        console.error(`[notes] error saving note of patient ${this.patientInfo.user_id}:`, e);
      }
    },
    async deleteNote(noteId) {
      try {
        const token = this.$store.getters['user/auth/getAuth'].accessToken;
        await this.$http.delete(`api/v1.1/patients/${this.patientInfo.user_id}/notes/${noteId}`, { headers: { Authorization: token } });
        this.patientNotes = this.patientNotes.filter(note => note.id !== noteId);
      } catch (e) {
        console.error(`[notes] error saving note of patient ${this.patientInfo.user_id}:`, e);
      }
    },
  },
  mounted() {
    this.fetchPatientNotes();
  },
};
</script>

<style lang="scss" scoped>
.notes-modal {
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  z-index: 11;
  width: 23.75rem;
  height: 33.125rem;
  max-height: calc(100% - 7.5rem);
  background-color: $white;
  border-radius: 0.25rem;

  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: break-wuk('medium', 's')) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 26;
    border-radius: 0;
    max-height: initial;
  }
}

</style>

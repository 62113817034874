<template>
  <div class="new-message">
    <div class="new-message__area">
      <div ref="userInput"
          data-testid="input_newMessage"
          contenteditable="true"
          class="user-input"
          placeholder="Start typing..."
          @keydown="handleKey" />
      <div class="new-message__actions">
        <sword-button-wuk icon="add"
                          theme="transparent"
                          round
                          aria-label="click to upload files."
                          @click.native="openFilesUpload">
        </sword-button-wuk>
        <sword-button-wuk icon="send"
                          theme="transparent"
                          round
                          aria-label="click to send the message."
                          data-testid="button_sendMessage"
                          @click.native="sendMessage">
        </sword-button-wuk>
        <input  type="file"
                ref="fileUploadInput"
                multiple
                @change="uploadFiles" />
      </div>
    </div>
    <p class="fc-red t4 new-message__error-message" v-if="invalidFileSizeMessage">
      <sword-icon name="circle-alert"></sword-icon>
      {{ $t(invalidFileSizeMessage, { maxSize: 25 })}}
    </p>
    <transition-group name="new-message__file-preview"
                      tag="div"
                      class="new-message__file-preview"
                      v-if="uploadedFiles.length">
      <uploaded-file-preview  v-for="(file, index) in uploadedFiles"
                              :key="file.name"
                              :file="file"
                              :file-name="file.name"
                              :file-type="file.type"
                              @remove-file="removeFile(index)">
      </uploaded-file-preview>
    </transition-group>
  </div>
</template>

<script>
import { tools } from '@sword-health/ui-core';
import { SENDBIRD } from '@/configs/constants';

import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue';
import SwordIcon from '@ui-kit/components/icons/SwordIcon.vue';

import UploadedFilePreview from '@/components/chat/conversation/UploadedFilePreview.vue';

const ENTER_KEY_CODE = 13;
const TYPING_DEBOUNCE_TIME = 2000;
const DRAFT_DEBOUNCE_TIME = 250;

export default {
  name: 'NewMessage',
  components: { SwordButtonWuk, SwordIcon, UploadedFilePreview },
  props: {
    value: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isTyping: false,
      uploadedFiles: [],
      invalidFileSizeMessage: '',
    };
  },
  watch: {
    isTyping() {
      this.$emit('typing', this.isTyping);
    },
  },
  mounted() {
    this.$refs.userInput.innerHTML = this.value || '';
  },
  methods: {
    sendMessage() {
      if (this.invalidFileSizeMessage) {
        this.invalidFileSizeMessage = '';
      }
      const text = this.$refs.userInput.innerText;
      if ((!text || !text.length) && !this.uploadedFiles.length) {
        return;
      }
      // Sends message content up to be dealed with and gets component ready for new messages
      this.$emit('send', text, this.uploadedFiles);
      this.$emit('input', '');
      this.$refs.userInput.innerHTML = '';
      this.$refs.userInput.focus();
      this.uploadedFiles = [];
    },
    openFilesUpload() {
      if (this.invalidFileSizeMessage) {
        this.invalidFileSizeMessage = '';
      }
      this.$refs.fileUploadInput.click();
    },
    uploadFiles(event) {
      const validUploadedFiles = [ ...event.target.files ].filter(file => file.size <= SENDBIRD.MAX_FILE_SIZE);
      if ((event.target.files.length === 1 && event.target.files[0].size > SENDBIRD.MAX_FILE_SIZE) || !validUploadedFiles.length) {
        this.invalidFileSizeMessage = 'CHAT.ERROR.MAX_SIZE';
        return;
      }

      if (validUploadedFiles.length < event.target.files.length) {
        this.invalidFileSizeMessage = 'CHAT.ERROR.SOME_FILES_MAX_SIZE';
      }

      this.uploadedFiles.push(...validUploadedFiles);
      this.$refs.fileUploadInput.value = null;
    },
    updateDraftMessage: tools.debounce(function draft() {
      const text = this.$refs.userInput.innerText;
      this.$emit('input', text);
    }, DRAFT_DEBOUNCE_TIME),
    debounceTypingOff: tools.debounce(function htOff() {
      this.isTyping = false;
    }, TYPING_DEBOUNCE_TIME),
    handleTyping() {
      this.isTyping = true;
      this.updateDraftMessage();
      this.debounceTypingOff();
    },
    handleKey(event) {
      this.handleTyping();
      if (event.keyCode !== ENTER_KEY_CODE || event.shiftKey) {
        return;
      }
      this.sendMessage(event);
      event.preventDefault();
    },
    removeFile(fileIndex) {
      this.uploadedFiles.splice(fileIndex, 1);
    },
  },

};
</script>

<style scoped lang="scss">
.new-message {
  margin-top: 10px;
  padding-top: 1.25em;
  border-top: 1px solid $auxiliar-color;

  @media screen and (max-width: $max-tablet-size) {
    border-width: 0px;
    margin-top: 0px;
  }

  &__area {
    display: flex;

    .user-input,
    button {
      outline: none;
      border: 0;
    }

    .user-input {
      flex-grow: 1;
      resize: none;

      max-height: 250px;
      min-height: 25px;
      background-color: transparent;

      box-sizing: border-box;
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-all;

      overflow: scroll;
      overflow-x: hidden;
      overflow-y: auto;

      padding-top: 4px;

      @media screen and (max-width: $max-tablet-size) {
        background: getColor($greys, 7);
        min-height: 40px;
        padding: 0.5em 0.9em;
        border-radius: 20px;
      }
    }

    .user-input:empty:before {
      content: attr(placeholder);
      display: block; /* For Firefox */
      filter: contrast(15%);
    }

    .new-message__actions {
      .sword-button-wuk.--medium.round {
        margin: 0;
        min-width: initial;
        border: none;
        ::v-deep {
          & .sword-icon.svg-icon {
            width: 1rem;
            height: 1rem;
            margin: auto;
            fill: $context-color-1;
          }
        }
      }

      input[type=file] {
        display: none;
      }
    }
  }

  &__error-message {
    margin: 0;
    .sword-icon.svg-icon {
      width: 0.75rem;
    }
  }

  &__file-preview {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    max-width: calc(100% - 5rem);

    &-enter-active, &-leave-active, &-move {
      transition: opacity 0.5s ease, transform 0.5s ease;
    }

    &-enter, &-leave-to {
      opacity: 0;
      transform: translateY(50%);
    }
  }
}
</style>


<template>
<transition name="modal">
  <div class="modal-backdrop"
       ref="patient-sidebar-modal-backdrop"
       @click="handleCloseFromPropagation">
    <div :class="['member-summary-bar', { 'member-summary-bar--loading': !patientInfo }]">
      <pulse-loader-v2 v-if="!patientInfo && !errorMessage"></pulse-loader-v2>
      <div v-else-if="errorMessage" class="sidebar-fetch-error center-text">
        <sword-icon name="circle-alert"></sword-icon>
        <p> Unable to load</p>
        <p class="-my_0"> {{ errorMessage }} </p>
        <p class="-my_0"> Please contact support </p>
      </div>
      <patient-summary-overview v-else
                                :bloom-protocol="patientInfo.protocol"
                                :profile-info="patientInfo.profile"
                                :clinical-info="patientInfo.clinical"
                                :reminders="patientInfo.reminders"
                                :goals="patientInfo.goals"
                                :protocol="protocolInfo"
                                :age="age"
                                :current-state="patientInfo && patientInfo.profile.current_state"
                                :contacts="contacts"
                                :clinical-portal-base-url="clinicalPortalBaseUrl"
                                @close-side-bar="$emit('handle-close')"
                                @open-ui="openUi"
                                @copy-to-clipboard="copyToClipboard"
                                @send-to-user="sendToUser">
      </patient-summary-overview>
    </div>
  </div>
</transition>
</template>

<script>
import { mapGetters } from 'vuex';

// Components
import SwordIcon from '@ui-kit/components/icons/SwordIcon.vue';
import PatientSummaryOverview from '@ui-kit/components/patient-summary-overview/patient-summary-overview.vue';
import PulseLoaderV2 from '@ui-kit/components/loaders/PulseLoaderV2.vue';

import configs from '@/configs';
import { URLS, MEMBER_MODALS } from '@/configs/constants';

const APPOINTMENT_TYPES = [ 'reassessment', 'check_in_call' ];

export default {
  name: 'patientSummarySideBar',
  components: {
    SwordIcon,
    PatientSummaryOverview,
    PulseLoaderV2,
  },
  emits: [ 'handle-open-modal', 'handle-close' ],
  data() {
    return {
      errorMessage: '',
      clinicalPortalBaseUrl: configs.get('CLINICAL_PORTAL_URL'),
      MEMBER_MODALS,
    };
  },
  mounted() {
    const patientId = this.$store.getters['chat/activeChannel/getMemberId'];

    if (!patientId) {
      this.errorMessage = 'Patient don\'t have id assigned';
      return;
    }

    this.$store.dispatch('patient/fetchPatientSummarySideBarInfo', patientId);
  },
  computed: {
     ...mapGetters({
      loading: 'status/loading',
      patientInfo: 'patient/getMemberProfile',
    }),
    age() {
      const birthDate = this.patientInfo?.profile.birthdate;
      return birthDate && this.$date().getAge(birthDate);
    },
    contacts() {
      if (!this.patientInfo) {
        return [];
      }

      const phoneNumber = this.patientInfo.profile.phone ? `${this.patientInfo.profile.phone.prefix} ${this.patientInfo.profile.phone?.number}` : '';

      const phoneWarningMessage = this.patientInfo.profile.phone.can_receive_sms === false
        ? this.$t('webchat_sidebar_profile.number_not_eligible_sms')
        : undefined;

      return [
        { label: 'PROFILE.PERSONAL_INFORMATION.PREFERRED_NAME', content: this.patientInfo.profile.preferred_name },
        { label: 'COMMON.EMAIL', content: this.patientInfo.profile.email },
        { label: 'copy_1289', content: phoneNumber, showCallButton: true, warningMessage: phoneWarningMessage },
      ];
    },
    protocolInfo() {
      if (!this.patientInfo.protocol) {
        return undefined;
      }
      return {
        name: this.patientInfo.protocol.protocol_name,
        currentPhase: this.patientInfo.protocol.protocol_current_phase_name,
        totalPhases: this.patientInfo.protocol.protocol_total_phases,
        startDate: this.formatMonthAndDay(this.patientInfo.protocol.protocol_created_at),
        endDate: this.formatMonthAndDay(this.patientInfo.protocol.protocol_expires_at),
        outdated: this.isOutdated(this.patientInfo.protocol.protocol_expires_at),
      };
    },
  },
  methods: {
    handleCloseFromPropagation($event) {
      if (this.$refs['patient-sidebar-modal-backdrop'] === $event.target) {
        this.$emit('handle-close');
      }
    },
    async copyToClipboard({ context, content }) {
      let contentToCopy;
      if (content) {
        contentToCopy = content;
      } else {
        // since not content, get context to get the right content ouside component scope to copy
        switch (context) {
          case 'reassessment-link':
            contentToCopy = await this.generateAppointmentLink('reassessment');
            break;
          case 'check-in-link':
            contentToCopy = await this.generateAppointmentLink('check_in_call');
            break;
          default:
            console.warn('[patient-sidebar] Requested unsupported appointment type');
        }
      }
      if (contentToCopy) {
        try {
          await navigator.clipboard.writeText(contentToCopy);
          this.$notify.success(this.$t('COPIED.CLIPBOARD'));
        } catch (error) {
          this.$notify.error(this.$t('copy_89'));
        }
        return;
      }
      console.warn('[patient-sidebar] Coulnd\'t get any content to copy');
    },
    openUi({ context }) {
      switch (context) {
        case 'dt-message':
          this.handleOpenModal(MEMBER_MODALS.SEND_DT_MESSAGE);
          break;
        case 'notes':
          this.handleOpenModal(MEMBER_MODALS.NOTES);
          break;
        default:
          console.warn('[patient-sidebar] Requested unsupported option');
      }
    },
    async generateAppointmentLink(appointmentType, sendBySms = false) {
      try {
        if (!appointmentType || !APPOINTMENT_TYPES.includes(appointmentType)) {
          return null;
        }
        const { accessToken } = this.$store.getters['user/auth/getAuth'];
        const { data } = await this.$http.post(
          `${URLS.THERAPIST_API}v1/patients/${this.patientInfo.profile.user_uuid}/generate-appointment-link`,
          {
            type: appointmentType,
            send_sms: sendBySms,
          },
          { headers: { Authorization: accessToken } },
        );

        return data?.url;
      } catch (e) {
        this.$notify.error(this.$t('copy_89'));
        console.error(`It was not possible to generate a ${appointmentType} appointment link for member with id
        ${this.patientInfo.profile.user_uuid}.`);
      }

      return null;
    },
    async sendToUser({ context }) {
      try {
        switch (context) {
          case 'reassessment-link':
            await this.generateAppointmentLink('reassessment', true);
            break;
          case 'check-in-link':
            await this.generateAppointmentLink('check_in_call', true);
            break;
          default:
            console.warn('[patient-sidebar] Requested unsupported appointment type');
            throw Error('Unsupported appointment type');
        }

        const member = `${this.patientInfo.profile.firstname} ${this.patientInfo.profile.lastname}`;

        this.$notify.success(this.$t('APPOINTMENT_LINK.LINK_SENT_TO_MEMBER', { member }));
      } catch (e) {
        console.error(e);
      }
    },
    formatMonthAndDay(date) {
      return this.$date(date).format('MMM DD');
    },
    isOutdated(date) {
      return this.$date(date).diff(this.$date(), 'day') < 0;
    },
    handleOpenModal(modalType) {
      this.$emit('handle-open-modal', modalType, true);
    },
  },
};
</script>

<style lang="scss" scoped>
$member-summary-bar-width: 28rem;

.sidebar-fetch-error {
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    width: 3rem !important;
    height: 3rem !important;
    color: $primary-color !important;
  }
}

.member-summary-bar__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  z-index: 30;
}

.member-summary-bar-enter,
.member-summary-bar-leave-to {
  opacity: 0;
  transform: translateX(110%);
}

.member-summary-bar-enter-to,
.member-summary-bar-leave {
  opacity: 1;
  transform: translateX(0);
}

.member-summary-bar-enter-active {
  transition: all 0.3s ease-out;
}

.member-summary-bar-leave-active {
  transition: all 0.1s ease-in;
}

.member-summary-bar {
  position: fixed;
  right: 0;
  height: 100vh;
  width: $member-summary-bar-width;
  box-shadow: 0 0.25rem 0.75rem 0 rgb(31, 34, 44, 0.25);

  .patient-summary-overview {
    min-height: 100%;
  }

  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    width: $member-summary-bar-width;
    box-shadow: 0 0.25rem 0.75rem 0 rgb(31 34 44 / 25%);
  }

  @media screen and (max-width: $max-smartphone-size) {
    top: 0;
    left: 0;
    height: 100%;
  }
}
</style>

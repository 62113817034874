<template>
  <button @click="click($event)" :disabled="disabled" :aria-label="$t(text)">
    <sword-icon name="back-arrow"></sword-icon>
  </button>
</template>

<script>
import SwordIcon from '@ui-kit/components/icons/SwordIcon.vue';

export default {
  name: 'BackButton',
  components: { SwordIcon },
  props: {
    text: { default: 'copy_8' },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    click(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  fill: getColor($greys, 1);
  color: getColor($greys, 1);
  text-align: center;
  margin: 0;
  background: transparent;
  border-radius: 24px;
  border: none;

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    color: getColor($greys, 4);
    fill: getColor($greys, 4);
  }
}
</style>

const SMS_SCHEDULING_STATUS = {
  CANCELLED: 'CANCELLED',
  SCHEDULED: 'SCHEDULED',
  PROCESSED: 'PROCESSED',
  SENT_AUTOMATIC: 'SENT_AUTOMATIC',
};

const SMS_SCHEDULING_COPIES = {
  USER_BLOCKED_SMS: 'This member has blocked SMS sending',
  SCHEDULE: 'Send via SMS if no reply in 8h',
};

const SMS_SCHEDULED_COPIES = {
  SCHEDULED: 'SMS will be sent if no reply in 8h. Click to cancel.',
  PROCESSED: 'SMS has been sent',
  SENT_AUTOMATIC: 'SMS has been sent automatically',
};

export { SMS_SCHEDULING_STATUS, SMS_SCHEDULING_COPIES, SMS_SCHEDULED_COPIES };

import store from '@/store';
import SendBirdEvent from '@/scripts/sendbird-integration/SendBirdEvent';

export default {
  methods: {
    createChannelEvent() {
      const channelEvent = new SendBirdEvent();
      console.log('[events] setting up channel events');

      channelEvent.onMessageReceived = (channel, message) => {
        console.log('[channel-events] message received: ', channel, message);
        const activeChannel = store.getters['chat/activeChannel/getActiveChannel'];
        if (!activeChannel || activeChannel.url !== channel.url) {
          store.dispatch('chat/fetchUnreadMessagesCount');
        }
        store.dispatch('chat/setMemberStatus', { channel, isOnline: true });
        store.dispatch('chat/moveChannelAndMemberToTop', channel);
      };
      channelEvent.onChannelChanged = channel => {
        console.log('[channel-events] Channel changed: ', channel);
        const activeChannel = store.getters['chat/activeChannel/getActiveChannel'];
        if (activeChannel && activeChannel.url === channel.url) {
          activeChannel.markAsRead();
        }
        store.dispatch('chat/addOrUpdateChannel', channel);
      };
      channelEvent.onChannelParticipantCountChanged = channel => {
        console.log('[channel-events] Channel participant count changed: ', channel.url);
      };
      channelEvent.onUserEntered = (openChannel, user) => {
        console.log('[channel-events] User entered the chat: ', openChannel.url, user.userId);
      };
      channelEvent.onUserJoined = (groupChannel, user) => {
        console.log('[channel-events] User just joined the chat: ', groupChannel.url, user.userId);
      };
      channelEvent.onUserLeft = (groupChannel, user) => {
        console.log('[channel-events] User left the chat: ', groupChannel.url, user.userId);
      };
      channelEvent.onChannelHidden = groupChannel => {
        console.log('[channel-events] Channel got hidden: ', groupChannel.url);
      };
    },
  },
};

<template>
  <sword-modal v-show="showTimeoutModal"
               type="warning"
               header="copy_4416"
               body="copy:"
               @close="resetTimer"
  >
    <template slot="body">
      <p class="-pb_2 warning-text">{{ $t('copy_4417') }}</p>
      <timer v-if="activate"
             :events="['click', 'keypress', 'touchstart']"
             :reminders="[60]"
             :duration="timeout"
             @change="setTimer"
             @idle="logout"
             @remind="triggerTimeoutModal"
             @reset="closeTimeoutModal"
      ></timer>
    </template>
    <template slot="footer">
      <sword-button-wuk theme="primary"
                        text="copy_2"
                        @click.native="resetTimer"
      >
      </sword-button-wuk>
    </template>
  </sword-modal>
</template>

<script>
import { SESSION_TIMEOUT_CONFIG } from '@/configs/constants';

import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue';
import SwordModal from '@ui-kit/components/modals/SwordModal.vue';
import Timer from './timer.vue';


export default {
  name: 'timeoutManager',
  components: {
    SwordButtonWuk,
    SwordModal,
    Timer,
  },
  props: {
    activate: {
      type: Boolean,
      required: true,
    },
    timeout: {
      type: Number,
      default: Number(SESSION_TIMEOUT_CONFIG.sessionLogoutTimeout),
    },
  },
  data() {
    return {
      showTimeoutModal: false,
      timer: '',
    };
  },
  methods: {
    resetTimer() {
      // to reset the timer because the modal component captures every click event inside.
      const event = new Event('click');
      window.dispatchEvent(event);
      this.closeTimeoutModal();

    },
    triggerTimeoutModal() {
      this.showTimeoutModal = true;
      this.updateDocumentTitle(this.timer);
    },
    closeTimeoutModal() {
      this.showTimeoutModal = false;
      this.updateDocumentTitle();
    },
    logout() {
      this.$nextTick(() => this.$emit('logout'));
      this.closeTimeoutModal();
      this.$router.push({ name: 'Logout' });
    },
    setTimer(val) {
      this.timer = val;

      if (this.showTimeoutModal) {
        this.updateDocumentTitle(val);
      }
    },
    updateDocumentTitle(value) {
      const TIMER_REGEX = /(\[[0-9]{2}:[0-9]{2}\]\s)?(.+)/g;
      document.title = document.title.replace(TIMER_REGEX, (curr, timer, title) => (value ? `[${value}] ${title}` : title));
    },
  },
};
</script>
<style lang="scss" scoped>
.warning-text {
  @extend .fc-blue-8;
  line-height: $line-height-base;
  max-width: 30rem;
}
</style>

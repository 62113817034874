<template>
  <div class="--call">
    -- Call Message --
  </div>
</template>

<script>

/* REPLICATE TYPE SPECIFIC OPTIONS FROM MESSAGE.JS */

export default {
  name: 'CallMessage',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped lang="scss">
</style>

import SendBirdConnection from '@/scripts/sendbird-integration/SendBirdConnection';
import SendBirdSyncManagerAction from '@/scripts/sendbird-integration/SendBirdSyncManagerAction';
import store from '@/store';

export default {
  methods: {
    createConnectionHandler(errorCallback) {
      const sbSyncAction = new SendBirdSyncManagerAction();
      const syncManager = sbSyncAction.manager;
      const connectionManager = new SendBirdConnection();
      console.log('[events] setting up connection handler');

      connectionManager.onReconnectStarted = () => {
        console.log('[SendBird JS SDK] Reconnect : Started');
      };
      connectionManager.onReconnectSucceeded = async () => {
        console.log('[SendBird JS SDK] Reconnect : Succeeded');
        try {
          await store.dispatch('user/handleCookieAuth');
          await store.dispatch('chat/connectToSendbird');
          await store.dispatch('call/initSendbirdCall');
          await store.dispatch('chat/fetchChannelList', { isInit: true });
        } catch (error) {
          if (errorCallback) errorCallback();
        }

        syncManager.resumeSync();
      };
      connectionManager.onReconnectFailed = () => {
        console.log('[SendBird JS SDK] Reconnect : Failed');
        connectionManager.remove();
        if (errorCallback) errorCallback();
      };
    },
  },
};

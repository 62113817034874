<template>
  <div class="file-message">
    <file-avatar :file-extension="fileData.extension"
                :file-type="fileData.type"
                :file-src="message.url">
    </file-avatar>
    <div class="-ml_1 file-message__text">
      <a :href="message.url" download class="compact-text">{{ fileData.name }}</a>
      <p class="t4 -t_heavy compact-text">{{ fileData.extension.toUpperCase() }}</p>
    </div>
  </div>
</template>
<script>
import FileAvatar from '@/components/chat/conversation/FileAvatar.vue';

import { getFileData } from '@/scripts/utils';

export default {
  name: 'FileMessage',
  components: { FileAvatar },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fileData: {
        name: '',
        extension: '',
        type: '',
      },
    };
  },
  mounted() {
    this.fileData = getFileData(this.message.name, this.message.type);
  },
};
</script>

<style scoped lang="scss">

.file-message {
  display: flex;
  align-items: center;
  max-width: 27.5rem;

  p, a {
    color: $white;
    margin: 0;

    &:first-child {
      margin-bottom: 0.25rem;
    }
  }

  &__text {
    min-width: 0;
    flex: 1;
  }
}
</style>

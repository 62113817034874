<template>
  <p data-testid="text_message"
    class="message-text --text -mxy_0"
    v-html="protectFromXSS(message)" />
</template>

<script>
import { protectFromXSS } from '@/scripts/utils';

export default {
  name: 'TextMessage',
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      protectFromXSS,
    };
  },
};
</script>

<template>
  <div class="chat-window">
    <conversation-header class="conversation-header"
                         :picture="parsedMember.picture"
                         :gender="parsedMember.gender"
                         :channel-name="parsedMember.nickname"
                         :preferred-name="parsedMember.preferredName"
                         :video-call-room-id="videoCallRoomId"
                         :is-preventive="isPreventiveChannel"
                         :public-call-room-token="publicGroupCallToken"
                         :online="isOnline"
                         :has-automatic-chat-messages-on="parsedMember.hasAutomaticChatMessagesOn"
                         :localTime="parsedMember.localTime"
                         @toggle-automatic-chat-messages="toggleAutomaticChatMessages"
                         @handle-channel-name-click="openModal(MEMBER_MODALS.SUMMARY)" />
    <conversation-area class="conversation-area"
                       :members="channel.members"
                       :messages="messages"
                       :installed-app="installedApp"
                       @send-message-via-sms="sendMessageViaSms"
                       @cancel-schedule-sms="cancelScheduleSms"
                       @loadMessageList="fetchChannelMessages" />
    <new-message v-model="memberDraft"
                 class="new-message"
                 @typing="handleUserTyping"
                 @send="sendMessage" />

    <component v-if="modalMetadata"
               :is="modalMetadata.component"
               v-bind="modalMetadata.props"
               @handle-close="closeModal"
               @handle-open-modal="openModal">
    </component>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import messageCollectionEvents from '@/mixins/message-collection-events';
import dptActivityTracker from '@/mixins/dpt-activity-tracking';
import sbUtils from '@/scripts/sendbird-integration/utils';
import { MEMBER_MODALS, URLS } from '@/configs/constants';

import NewMessage from '@/components/chat/conversation/NewMessage.vue';
import ConversationArea from '@/components/chat/conversation/ConversationArea.vue';
import ConversationHeader from '@/components/chat/conversation/ConversationHeader.vue';
import MemberSummarySideBar from '@/components/MemberSummarySideBar.vue';
import NotesModal from '@/components/NotesModal.vue';
import SendMessageModal from '@/components/SendMessageModal.vue';

export const MEMBER_MODALS_COMPONENTS = {
  [MEMBER_MODALS.SUMMARY]: MemberSummarySideBar,
  [MEMBER_MODALS.NOTES]: NotesModal,
  [MEMBER_MODALS.SEND_DT_MESSAGE]: SendMessageModal,
};

export default {
  components: {
    NewMessage,
    ConversationArea,
    ConversationHeader,
    MemberSummarySideBar,
    NotesModal,
    SendMessageModal,
  },
  mixins: [ messageCollectionEvents, dptActivityTracker('ACTIVITY_CHAT') ],
  data() {
    return {
      videoCallRoomId: '',
      publicGroupCallToken: '',
      isMemberSideBarOpen: false,
      modalMetadata: null,
      MEMBER_MODALS,
      preferredName: '',
    };
  },
  computed: {
    ...mapGetters({
      channel: 'chat/activeChannel/getActiveChannel',
      messages: 'chat/activeChannel/getMessages',
      member: 'chat/activeChannel/getMember',
    }),
    installedApp() {
      return true;
    },
    parsedMember() {
      if (!this.member) {
        return {};
      }

      const { channel, patient, account } = this.member;
      const { user } = patient || {};
      const hasAutoMsgToggle = typeof (account?.is_automatic_chat_messages_enabled) === 'boolean';
      let localTime = null;

      // Necessary until sendbird sync
      if (Object.prototype.hasOwnProperty.call(this.member, 'preferredName')) {
        this.setPreferredName(this.member.preferredName);
      }

      try {
        localTime = patient?.timezone ? new Date().toLocaleTimeString('en-US', { timeZone: patient?.timezone }) : null;
      } catch (e) {
        console.error(e);
      }

      return {
        url: channel?.url,
        nickname: this.member.nickname,
        preferredName: this.preferredName,
        picture: user ? user.picture : undefined,
        gender: user ? user.gender : undefined,
        localTime,
        hasAutomaticChatMessagesOn: hasAutoMsgToggle
                                    ? !!account.is_automatic_chat_messages_enabled
                                    : null,
      };
    },
    isOnline() {
      return this.member?.connectionStatus === 'online';
    },
    memberDraft: {
      get() {
        return this.member?.draft ?? '';
      },
      set(value) {
        if (this.member) {
          this.setMemberDraft({ member: this.member, textMessage: value });
        }
      },
    },
    isPreventiveChannel() {
      return sbUtils.isPreventiveChannel(this.channel);
    },
  },
  async mounted() {
    this.setLoading('messages');
    // Events handlers for messages
    this.createMessageCollectionEvent(this.channel);
    this.resetChannelMessages();
    await this.fetchChannelMessages();
    this.setGroupCallInfo();
    this.setReady('messages');
  },
  methods: {
    setPreferredName(name) {
      this.preferredName = name;
    },
    ...mapMutations({
      setLoading: 'status/setLoading',
      setReady: 'status/setReady',
    }),
    ...mapActions({
      resetChannelMessages: 'chat/activeChannel/resetChannelMessages',
      fetchChannelMessages: 'chat/activeChannel/fetchChannelMessages',
      getGroupCallInfo: 'chat/activeChannel/getGroupCallInfo',
      sendSendbirdMessage: 'chat/activeChannel/sendMessage',
      sendSendbirdFileMessage: 'chat/activeChannel/sendFileMessage',
      setMemberDraft: 'chat/setMemberDraft',
    }),
    handleUserTyping(typing) {
      if (!this.channel.isGroupChannel()) {
        return;
      }
      if (typing) {
        this.channel.startTyping();
        return;
      }
      this.channel.endTyping();
    },
    async sendMessageViaSms(message) {
      try {
        const { firstName, lastName } = this.$store.getters['user/getUserDetails'];
        const { userId: memberSendbirdId } = this.member;
        const token = this.$store.getters['user/auth/getAuth'].accessToken;
        await this.$http.post(`${URLS.THERAPIST_API}v1/chat/message-notification`,
            {
              notification_type: 'no_reply_8_hour',
              sendbird_id: memberSendbirdId,
              sendbird_message_id: message.messageId,
              pt_name: `${firstName} ${lastName}`,
              message: message.message,
            }, { headers: { Authorization: token } });
      } catch (e) {
        console.error(`[sms-schedule] Error scheduling sms scheduling ${message.messageId}`, e);
      }
    },
    async cancelScheduleSms(message, smsSchedulingNotificationType = 'no_reply_8_hour') {
      try {
        const token = this.$store.getters['user/auth/getAuth'].accessToken;
        await this.$http.delete(`${URLS.THERAPIST_API}v1/chat/message-notification`, {
          headers: {
            Authorization: token,
          },
          data: {
            notification_type: smsSchedulingNotificationType,
            sendbird_message_id: message.messageId,
          },
        });
      } catch (e) {
        console.error(`[sms-schedule] Error canceling scheduled sms ${message.messageId}`, e);
      }
    },
    sendMessage(messageText, messageFiles = []) {
      // Check message existence
      if (!messageText && !messageFiles.length) {
        return;
      }
      // Requests sendbird to send message
      this.sendSendbirdMessage({ messageText, messageFiles });
    },
    async setGroupCallInfo() {
      const groupCallInfo = await this.getGroupCallInfo();
      if (!groupCallInfo) return;

      const { groupCallId: videoCallRoomId, publicGroupCallToken } = groupCallInfo;

      if (videoCallRoomId) {
        this.videoCallRoomId = videoCallRoomId;
      }
      if (publicGroupCallToken) {
        this.publicGroupCallToken = publicGroupCallToken;
      }

      console.log('[sendbird-group-call] has call room id', !!videoCallRoomId);
      console.log('[sendbird-group-call] has call room public token', !!publicGroupCallToken);
    },
    async toggleAutomaticChatMessages(updatedHasAutomaticChatMessagesOn) {
      try {
        const { account, patient } = this.member;
        const token = this.$store.getters['user/auth/getAuth'].accessToken;
        await this.$http.put(`${URLS.THERAPIST_API}v1/chat/member/${patient?.id}/automatic-chat-messages`,
            { is_automatic_chat_messages_enabled: updatedHasAutomaticChatMessagesOn }, { headers: { Authorization: token } });
        account.is_automatic_chat_messages_enabled = updatedHasAutomaticChatMessagesOn;
        const successMessage = updatedHasAutomaticChatMessagesOn
                               ? 'CHAT.AUTOMATIC_MESSAGING.NOTIFICATION.ON'
                               : 'CHAT.AUTOMATIC_MESSAGING.NOTIFICATION.OFF';
        this.$store.commit('chat/activeChannel/setMember', { ...this.member, account });
        this.$notify.success(this.$t(successMessage));
      } catch (error) {
        this.$notify.error(this.$t('copy_89'));
      }
    },
    getFormatPatientData() {
      return {
        user_id: this.member.patient.id,
        name: this.parsedMember.nickname,
        gender: this.parsedMember.gender,
        picture: this.parsedMember.picture,
      };
    },
    openModal(modalType, definePatientInfoProp = false) {
      this.modalMetadata = { component: MEMBER_MODALS_COMPONENTS[modalType] };
      if (definePatientInfoProp) {
        this.modalMetadata.props = { patientInfo: this.getFormatPatientData() };
      }
    },
    closeModal() {
      this.modalMetadata = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-window {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
}

.conversation-header {
  @media screen and (max-width: $max-tablet-size) {
    padding: $main-mobile-padding $main-mobile-padding $main-mobile-padding;
  }
}

.new-message {
  align-items: center;

  @media screen and (max-width: $max-tablet-size) {
    padding: $main-mobile-padding;
  }
}

.conversation-area {
  @media screen and (max-width: $max-tablet-size) {
    padding: 0px $main-mobile-padding;
  }
}
</style>
